import React, { useEffect } from "react"
import util from "../../../js/util"
import { useSelector } from "react-redux"

export const GoogleAuthBtn = () => {
  const containerId = "google-auth"
  const btnOpts = { theme: "outline", size: "large", width: 320 }
  const configs = useSelector((state) => state.vendorConfigs.configs)
  const vendorConfig = configs["google"][0]

  useEffect(() => {
    util.initGoogleIdentityServices(vendorConfig.clientId)
    util.renderSignInWIthGoogleButton(containerId, btnOpts)
  }, [])

  return (
    <div id={ containerId } style={{
      margin: "0 auto",
    }}></div>
  )
}